<script lang="ts" setup>
import { DashboardQuery } from '@dashboard'

/* === FETCH DATA === */
const companyId = getCurrentDivisionId()
const dateFormat = 'D MMM YYYY'
const { data: currentLeases } = useApiDashboard(
  companyId,
  DashboardQuery.MAP_ACTIVE_LEASES,
)
const { data: expiredLeases } = useApiDashboard(
  companyId,
  DashboardQuery.MAP_EXPIRED_LEASES,
)
const { data: allLeases } = useApiDashboard(
  companyId,
  DashboardQuery.MAP_ALL_LEASES,
)

const { data: otherLeases } = useApiDashboard(
  companyId,
  DashboardQuery.MAP_OTHER_LEASES,
)
/* === FETCH DATA === */
const activeTab = ref<'current' | 'expired' | 'other' | 'all'>('current')
const activeTabData = computed(() => {
  switch (activeTab.value) {
    case 'expired': {
      return expiredLeases.value
    }
    case 'all': {
      return allLeases.value
    }
    case 'current': {
      return currentLeases.value
    }
    case 'other': {
      return otherLeases.value
    }
    default: {
      return currentLeases.value
    }
  }
})
const activeMarkers = computed(() => {
  if (!activeTabData.value?.results?.data?.items) return []
  const markers = activeTabData.value.results.data.items?.map((item) => ({
    position: { lat: item.latitude, lng: item.longitude },
    data: item,
  }))

  return markers
})
const summaries = computed(() => [
  {
    key: 'current',
    label: 'Current Leases',
    total: currentLeases.value?.results?.data?.items?.length ?? 0,
  },
  {
    key: 'expired',
    label: 'Expired Leases',
    total: expiredLeases.value?.results?.data?.items?.length ?? 0,
  },
  {
    key: 'other',
    label: 'Other Leases',
    total: otherLeases.value?.results?.data?.items?.length ?? 0,
  },
  {
    key: 'all',
    label: 'All Leases',
    total: allLeases.value?.results?.data?.items?.length ?? 0,
  },
])

function onClick(event: any) {
  if (activeTab.value === event.key) return

  const _prevActive = activeTab.value
  activeTab.value = event.key

  useAnalytics({
    name: 'Google Maps Tab',
    showDivision: true,
    data: {
      Active: event.label,
      Total: event.total,
      'Previous Active': _prevActive,
    },
  })
}

const dayjs = useDayjs()
const googleApiKey = useRuntimeConfig().public.googleApiKey
const mapRef = ref<HTMLElement>()
const { map } = useGoogleMaps(mapRef, {
  zoom: 4,
  center: { lat: -25, lng: 134 },
  mapId: '4162bfc8ea6f3177',
})

useGoogleMapsMarkers(map, activeMarkers, {
  markerClusterer: true,
  infoWindow: ({ marker, data }) => {
    if (!data) {
      return false
    }

    //commencement date
    const commencementDate = data.commencementDate
      ? dayjs(data.commencementDate).format(dateFormat)
      : 'N/A'

    //expiry date
    const expiryDate = data.expiryDate
      ? dayjs(data.expiryDate).format(dateFormat)
      : 'N/A'

    // base rent
    const baseRent = new Intl.NumberFormat().format(data.baseRent)
    const imageZoom = 19
    const imgSrc = `background-image: url(https://maps.googleapis.com/maps/api/staticmap?center=${marker?.position?.lat},${marker?.position?.lng}&zoom=${imageZoom}&size=400x400&maptype=satellite&key=${googleApiKey}); background-size: cover`

    const content = /*html*/ `
      <div>
        <div class="flex flex-row items-stretch">
          <div
            class="h-full min-h-[130px] w-[130px] min-w-[130px] max-w-[130px]  rounded-md"
            style="${imgSrc}">
          </div>
          <div class="flex flex-auto flex-col gap-1 pl-2 text-black">
            <p class="break-words font-bold">${data.name}</p>
            <p>
              Status: <b>${title(data.status)}</b>
            </p>
            <p>
              Sector: <b>${title(data.sector)}</b>
            </p>
            <p>
              Commencement date: <b><time>${commencementDate}</time></b>
            </p>
            <p>
              Expiry date: <b><time>${expiryDate}</time></b>
            </p>
            <p>
              Base rent: <b>${baseRent}</b>
            </p>
            <p>
              <b>${data.address}</b>
            </p>
          </div>
        </div>
      </div>
      `

    return { content }
  },
})
</script>

<template>
  <DashboardWidget class="flex flex-col overflow-hidden !px-0 pb-0 [&>h3]:px-6">
    <div class="mb-4 flex self-center">
      <ButtonGroup>
        <ButtonGroupItem
          v-for="summary in summaries"
          :key="summary.key"
          @click="onClick(summary)"
        >
          {{ summary.label }}

          <span v-if="summary.total" class="text-semibold text-gray-500">
            ({{ summary.total }})
          </span>
        </ButtonGroupItem>
      </ButtonGroup>
    </div>

    <div ref="mapRef" class="h-[400px] w-full" />
  </DashboardWidget>
</template>
